import React from "react";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import BandaClass from "../images/bClassLatest.jpg";
import BandaClass1 from "../images/bc1.jpg";
import BandaClass2 from "../images/bc2.jpg";
import BandaClass3 from "../images/bc3.jpg";
import RfMasq from "../images/rfMasqLatest.jpg";

import { Link } from "gatsby";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "News",
    link: "news",
  },
];

class News extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     currentImage: LosMuetros2,
  //   };
  // }

  // handleImageSwitch = () => {
  //   this.setState((prevState) => ({
  //     currentImage:
  //       prevState.currentImage === LosMuetros2 ? LosMuetros : LosMuetros2,
  //   }));
  // };

  render() {
    return (
      <Layout
        title="News | RF Dance"
        pathname="news/"
        noHero="no-hero"
        className="news_page"
      >
        <Hero titleRed={`RF`} titleWhite={`News`} />

        <div className="content-block">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">
              {/* English */}

              <div className="english-event">
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=01/17/25">
                      <img src={BandaClass} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class</h3>
                    <div className="news_content_area">
                      <p>
                        <b>Get Ready to Learn some Banda!</b>
                      </p>
                      <p>
                        Join us for a fun Banda Class led by Christopher &
                        Destany on Friday, January 17th at 9:00 pm at RF Dance
                        Center. Secure your pre-sale spot for $30
                        (Non-Refundable), or pay $40 at the door. RF Members
                        attend for free. Don't just sit around at parties when
                        the banda is playing! No partner is necessary, and all
                        levels are welcome. Stick around after the class for an
                        extra hour of practice and social dancing to Cumbia and
                        Banda! Secure your spot and join the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=01/17/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/07/25">
                      <img src={BandaClass1} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 02/07</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for a fun Banda Class led by Christopher &
                        Destany at 9:00 pm at RF Dance Center (1517 N. Main st,
                        Santa Ana, ca 92701). Secure your pre-sale spot for $30
                        (Non-Refundable), or pay $40 at the door. RF Members
                        attend for free. Don't just sit around at parties when
                        the banda is playing! No partner is necessary, and all
                        levels are welcome. Stick around after the class for an
                        extra hour of practice and social dancing to Cumbia and
                        Banda! Secure your spot and join the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/07/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/14/25">
                      <img src={BandaClass2} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 02/14</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for a fun Banda Class led by Christopher &
                        Destany at 9:00 pm at RF Dance Center (1517 N. Main st,
                        Santa Ana, ca 92701). Secure your pre-sale spot for $30
                        (Non-Refundable), or pay $40 at the door. RF Members
                        attend for free. Don't just sit around at parties when
                        the banda is playing! No partner is necessary, and all
                        levels are welcome. Stick around after the class for an
                        extra hour of practice and social dancing to Cumbia and
                        Banda! Secure your spot and join the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/14/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/21/25">
                      <img src={BandaClass3} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 02/21</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for a fun Banda Class led by Christopher &
                        Destany at 9:00 pm at RF Dance Center (2201 w first st,
                        unit B, Santa Ana). Secure your pre-sale spot for $30
                        (Non-Refundable), or pay $40 at the door. RF Members
                        attend for free. Don't just sit around at parties when
                        the banda is playing! No partner is necessary, and all
                        levels are welcome. Stick around after the class for an
                        extra hour of practice and social dancing to Cumbia and
                        Banda! Secure your spot and join the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/21/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=01/31/25">
                      <img src={RfMasq} alt="  RF Masquerade Social " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Masquerade Social</h3>
                    <div className="news_content_area">
                      <p>
                        Don’t Miss Night of Mystery and Dance at RF Masquerade
                        Social! Join us on Friday, January 31st, from 9:00 PM to
                        1:00 AM for an evening of dancing and music.
                      </p>
                      <p>
                        <b>Location:</b>
                      </p>
                      <p>RF Dance Center 1517 N. Main St, Santa Ana, CA</p>
                      <p>
                        <b>Agenda:</b>
                      </p>
                      <ul>
                        <li>
                          <p>
                            9:00 PM: Start the night with a dynamic Banda Class,
                            led by Christopher & Destany.
                          </p>
                        </li>
                        <li>
                          <p>
                            Social Dancing: Dance the night away until 1:00 AM
                            with music from DJ Real Deal and DJ Noe.
                          </p>
                        </li>
                      </ul>
                      <p>
                        <b>Price:</b>
                      </p>
                      <ul>
                        <li>
                          <p>$35 Pre-Sale (Non-refundable)</p>
                        </li>
                        <li>
                          <p>$45 Door Price</p>
                        </li>
                        <li>
                          <p>
                            RF Members: Free Entry from 9:00 PM to 11:00 PM
                            (Pre-registration required by Friday, 1/31).
                          </p>
                        </li>
                      </ul>
                      <p>
                        {" "}
                        Dress Code: Masquerade attire in Black and Gold. Put on
                        your favorite mask and outfit to match the theme!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=01/31/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Spanish */}
              <h3>Spanish:</h3>
              <div className="spanish-event">
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=01/17/25">
                      <img src={BandaClass} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Clase de Banda</h3>
                    <div className="news_content_area">
                      <p>
                        <b>Prepárate para aprender Banda!</b>
                      </p>
                      <p>
                        Únete a una divertida clase de Banda dirigida por
                        Christopher y Destany el viernes 17 de Enero a las 9:00
                        p.m. en RF Dance Center. Asegura tu lugar en preventa
                        por $30 (no reembolsable), o paga $40 en la puerta. ¡Los
                        miembros de RF asisten gratis! No te quedes sentado en
                        las fiestas cuando suena la banda. ¡No necesitas pareja
                        y todos los niveles son bienvenidos! Quédate después de
                        la clase para una hora extra de práctica y baile!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=01/17/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/07/25">
                      <img src={BandaClass1} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 02/07</h3>
                    <div className="news_content_area">
                      <p>
                        Únete a una divertida clase de Banda dirigida por
                        Christopher y Destany a las 9:00 p.m. en RF Dance Center
                        (1517 N. Main st, Santa Ana, ca 92701). Asegura tu lugar
                        en preventa por $30 (no reembolsable), o paga $40 en la
                        puerta. ¡Los miembros de RF asisten gratis! No te quedes
                        sentado en las fiestas cuando suena la banda. ¡No
                        necesitas pareja y todos los niveles son bienvenidos!
                        Quédate después de la clase para una hora extra de
                        práctica y baile social de Cumbia y Banda. ¡Asegura tu
                        lugar y únete a la fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/07/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/14/25">
                      <img src={BandaClass2} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 02/14</h3>
                    <div className="news_content_area">
                      <p>
                        Únete a una divertida clase de Banda dirigida por
                        Christopher y Destany a las 9:00 p.m. en RF Dance Center
                        (1517 N. Main st, Santa Ana, ca 92701). Asegura tu lugar
                        en preventa por $30 (no reembolsable), o paga $40 en la
                        puerta. ¡Los miembros de RF asisten gratis! No te quedes
                        sentado en las fiestas cuando suena la banda. ¡No
                        necesitas pareja y todos los niveles son bienvenidos!
                        Quédate después de la clase para una hora extra de
                        práctica y baile social de Cumbia y Banda. ¡Asegura tu
                        lugar y únete a la fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/14/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/21/25">
                      <img src={BandaClass3} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 02/21</h3>
                    <div className="news_content_area">
                      <p>
                        Únete a una divertida clase de Banda dirigida por
                        Christopher y Destany a las 9:00 p.m. en RF Dance Center
                        (2201 w first st, unit B, Santa Ana). Asegura tu lugar
                        en preventa por $30 (no reembolsable), o paga $40 en la
                        puerta. ¡Los miembros de RF asisten gratis! No te quedes
                        sentado en las fiestas cuando suena la banda. ¡No
                        necesitas pareja y todos los niveles son bienvenidos!
                        Quédate después de la clase para una hora extra de
                        práctica y baile social de Cumbia y Banda. ¡Asegura tu
                        lugar y únete a la fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=02/21/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=01/31/25">
                      <img src={RfMasq} alt="  RF Masquerade Social " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Masquerade Social</h3>
                    <div className="news_content_area">
                      <p>
                        No te pierdas una noche de misterio y baile en el RF
                        Masquerade Social! Únete a nosotros el viernes 31 de
                        enero, de 9:00 PM a 1:00 AM, para una velada llena de
                        baile y música.
                      </p>
                      <p>
                        <b>Ubicación:</b>
                      </p>
                      <p>RF Dance Center 1517 N. Main St, Santa Ana, CA</p>
                      <p>
                        <b>Agenda:</b>
                      </p>
                      <ul>
                        <li>
                          <p>
                            9:00 PM: Comienza la noche con una dinámica clase de
                            Banda, dirigida por Christopher y Destany.
                          </p>
                        </li>
                        <li>
                          <p>
                            Baile Social: Baila toda la noche hasta la 1:00 AM
                            con música de DJ Real Deal y DJ Noe.
                          </p>
                        </li>
                      </ul>
                      <p>
                        <b>Precios:</b>
                      </p>
                      <ul>
                        <li>
                          <p>$35 Preventa (No reembolsable)</p>
                        </li>
                        <li>
                          <p>$45 Entrada en la puerta</p>
                        </li>
                        <li>
                          <p>
                            Miembros de RF: Entrada gratuita de 9:00 PM a 11:00
                            PM (Es necesario registrarse antes del viernes
                            31/01).
                          </p>
                        </li>
                      </ul>
                      <p>
                        {" "}
                        Código de vestimenta: Atuendo de mascarada en negro y
                        dorado. Ponte tu máscara y outfit favorito para seguir
                        el tema, ¡y hagamos de esta una noche inolvidable!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=01/31/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default News;
