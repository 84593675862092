import React, { useEffect } from "react";
import MindBodyWidget from "../../components/mindbody";
import TeamImg from "../../images/team.jpeg";
import Layout from "../../components/layout-default";
import Breadcrumbs from "../../components/breadcrumbs";
import ErrorBoundary from "../../components/ErrorBoundary";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Group Classes",
    link: "classes",
  },
];

export default () => {
  const scriptID = "mb-script-01";

  useEffect(() => {
    const timer = setInterval(() => {
      var mbScript =
        typeof document !== `undefined`
          ? document.querySelector(
              `[data-link-class=healcode-contract-text-link] .healcode-link`
            )
          : undefined;
      console.log("trying..");
      if (mbScript) {
        clearInterval(timer);
        const wrapper =
          typeof document !== `undefined`
            ? document.querySelector(".contract-wrapper")
            : undefined;
        wrapper.classList.add("is-active");
      }
    }, 1000);
  });

  return (
    <Layout
      title="Dance Classes Schedule in Orange County - RF Dance"
      description="Want to learn salsa and bachata dance moves? Studio. Check out the dance classes schedule & enrol yourself in the best dance classes at RF Dance."
      pathname="classes/"
      img={TeamImg}
      noHero="no-hero"
      className="pages"
    >
      <div className="container">
        <Breadcrumbs crumbs={crumbs} />
        <p
          style={{
            position: `absolute`,
            margin: `4em auto`,
            zIndex: -1,
            left: `50%`,
            marginLeft: `-70px`,
          }}
        >
          Loading classes...
        </p>
        <div className="contract-wrapper">
          <h3 className="contract-header">Become an RF Member.</h3>
          <p className="contract-body">
            Become an RF Member and gain access to exclusive benefits.
          </p>
          <p className="contract-body">
            Unlimited RF Group Classes, Discounted Private Lessons, Monthly
            Socials, Over 55 Classes Offered.
          </p>
          <p className="contract-body">Salsa, Bachata, Cumbia, Banda & More!</p>
          <ErrorBoundary>
            <MindBodyWidget
              id={scriptID}
              type="contract-link"
              version="0.2"
              linkClass="healcode-contract-text-link"
              siteId="62245"
              mbSiteId="222416"
              innerHtml="Become an RF Member"
              serviceId="106"
            />
          </ErrorBoundary>
        </div>
        <ErrorBoundary>
          <MindBodyWidget
            id="mb-script-02"
            type="schedules"
            widgetPartner="object"
            widgetId="d48507309b0"
            widgetVersion="1"
          />
        </ErrorBoundary>
      </div>
      <style jsx="jsx">{`
        .contract-wrapper {
          text-align: center;
          padding: 3em 0;
          background-color: #fff;
          display: none;
        }
        .contract-wrapper a {
          background-color: #209cee;
          padding: 1em 2em;
          border-radius: 27px;
          color: #fff;
          border: 1px solid #209cee;
        }
        .contract-wrapper a:hover {
          background-color: #fff;
          color: #209cee;
        }
        .contract-header,
        .contract-body {
          display: none;
        }
        .contract-wrapper.is-active {
          display: block;
        }
        .contract-wrapper.is-active .contract-header,
        .contract-wrapper.is-active .contract-body {
          display: block;
        }
        .contract-body {
          max-width: 80%;
          margin: 0 auto 3em;
        }
      `}</style>
    </Layout>
  );
};
