import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import RfLatin from "../images/latin.jpg";
import RfReno from "../images/reno.jpg";
import BravoFest from "../images/LABravoFest.jpg";

import "../styles/mindbody.scss";
const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Teams",
    link: "Teams",
  },
  {
    label: "Performance & Events",
    link: "performance-events",
  },
];

const Performance = () => {
  return (
    <>
      <Layout
        title="Best Studio Rentals in Orange County | RF Dance"
        description="Discover the best studio rentals in Orange County and unlock a world of possibilities for your next project. Book now and start turning your vision into reality."
        pathname="account"
        noHero="no-hero"
      >
        <Hero titleRed={`RF`} titleWhite={`Performance & Events`} />
        <div className="content-block all-performance">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">
              <div className="events_performance">
                <div className="news_item">
                  <div className="news_content">
                    <h1 className="news_heading">
                      RF Golden Rose Take Over Performer Sign Up
                    </h1>
                    <h3 className="news_heading">
                      RF Golden Rose Take Over Performer Sign Up 01/30/2025
                    </h3>
                    <h4 className="news_heading">Location:</h4>
                    <p>7115 Beach Blvd, Buena Park, CA 90620</p>
                    <p>
                      RF Takeovers are great to showcase our dedication and
                      talent. Let’s represent RF Dance with pride, celebrate our
                      hard work, and enjoy some fun time together. Looking
                      forward to a successful event. Let’s make it a great RF
                      Takeover! (Bring your ID’s and complete costume)
                    </p>
                    <h4 className="news_heading">Line Up:</h4>
                    <p>Call time | Showtime </p>
                    <u>
                      <b>
                        <p>Set 1</p> <p>8:00pm | 9:00pm</p>{" "}
                      </b>
                    </u>
                    <p>(1)Bachata 1 (2) Salsa 2 </p>
                    <u>
                      <b>
                        <p>Set 2 </p>
                        <p>8:30pm | 9:30pm</p>
                      </b>
                    </u>
                    <p>(3)Cumbia (4)Salsa 1 (5) Bachata 3</p>
                    <u>
                      <b>
                        <p>Set 3</p>
                        <p> 9:00pm | 10:00pm</p>
                      </b>
                    </u>
                    <p> (6)Bachata 2 (7)Salsa 3</p>
                    <p>
                      {" "}
                      <b>(Line Up is subject to changes)</b>
                    </p>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=59&sVT=90&sLevel=8&sView=day&sLoc=0&sTrn=100000131&date=01/30/25">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="events_performance">
                <div className="news_item">
                  <div className="news_content">
                    <h1 className="news_heading">
                      RF Mamba Lounge Take Over Performer Sign Up
                    </h1>
                    <h3 className="news_heading">
                      RF Mamba Lounge Take Over Performer Sign Up 01/24/2025
                    </h3>
                    <h4 className="news_heading">Location:</h4>
                    <p>
                      The Mamba Lounge 17296 Beach Blvd Huntington Beach, CA
                      92647
                    </p>
                    <p>
                      RF Takeovers are great to showcase our dedication and
                      talent. Let’s represent RF Dance with pride, celebrate our
                      hard work, and enjoy some fun time together. Looking
                      forward to a successful event. Let’s make it a great RF
                      Takeover! (Bring your ID’s and complete costume)
                    </p>
                    <h4 className="news_heading">Line Up:</h4>
                    <p>Call time | Showtime </p>
                    <u>
                      <b>
                        <p>Set 1</p> <p>8:00pm | 9:00pm</p>{" "}
                      </b>
                    </u>
                    <p>(1)Bachata 1 (2) Salsa 2</p>
                    <u>
                      <b>
                        <p>Set 2 </p>
                        <p>8:30pm | 9:30pm</p>
                      </b>
                    </u>
                    <p>(3)Cumbia (4)Salsa 1 (5) Bachata 3</p>
                    <u>
                      <b>
                        <p>Set 3</p>
                        <p> 9:00pm | 10:00pm</p>
                      </b>
                    </u>
                    <p>(6)Bachata 2 (7)Salsa 3</p>
                    <p>
                      {" "}
                      <b>(Line Up is subject to changes)</b>
                    </p>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=59&sVT=90&sLevel=8&sView=day&sLoc=0&sTrn=100000131&date=01/24/25">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="events_performance">
                <div className="news_item">
                  <div className="news_content">
                    <h1 className="news_heading">
                      LA Bachata Festival Performer Sign Up
                    </h1>
                    <p>
                      Join us at the LA Bachata Festival, February 13-17, 2025,
                      featuring Artist around the world. Take advantage of the
                      opportunity to learn from instructors from all over the
                      world, watch performances, and social dance all night!
                    </p>
                    <h4 className="news_heading">
                      The Main Hotel is 333 Universal Hollywood Dr, Universal
                      City, CA 91608
                    </h4>
                    <h4 className="news_heading">
                      <b>
                        <Link to="https://labachatafestival.ticketspice.com/la-bachata-festival-feb-2025">
                          Link for Tickets
                        </Link>
                      </b>
                    </h4>
                    <h4>
                      Step 1: Choose the Performer Pass and Fill in the
                      information Required
                    </h4>
                    <h4>
                      Step 2: On Coupon Code us code “RFDANCE” to Save $10
                    </h4>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=59&sVT=90&sView=day&sLoc=0&sTrn=100000131&date=02/13/25">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="events_performance">
                <div className="news_item">
                  <div className="news_content">
                    <h1 className="news_heading">
                      RF Masquerade Social Performer Sign Up
                    </h1>
                    <h3 className="news_heading">
                      RF Masquerade Social Performer Sign Up 01/31/2025
                    </h3>
                    <h4 className="news_heading">Location:</h4>
                    <p>RF Dance Center 1517 N. Main St, Santa Ana, CA 92701</p>
                    <p>
                      RF Takeovers are great to showcase our dedication and
                      talent. Let’s represent RF Dance with pride, celebrate our
                      hard work, and enjoy some fun time together. Looking
                      forward to a successful event. Let’s make it a great RF
                      Takeover! (Bring your ID’s and complete costume)
                    </p>
                    <h4 className="news_heading">Line Up:</h4>
                    <p>Call time | Showtime </p>
                    <u>
                      <b>
                        <p>Set 1</p> <p>8:00pm | 9:00pm</p>{" "}
                      </b>
                    </u>
                    <p>(1)Bachata 1 (2) Salsa 2</p>
                    <u>
                      <b>
                        <p>Set 2 </p>
                        <p>8:30pm | 9:30pm</p>
                      </b>
                    </u>
                    <p>(3)Cumbia (4)Salsa 1 (5) Bachata 3</p>
                    <u>
                      <b>
                        <p>Set 3</p>
                        <p>9:00pm | 10:00pm</p>
                      </b>
                    </u>
                    <p>(6)Bachata 2 (7)Salsa 3</p>
                    <p>
                      {" "}
                      <b>(Line Up is subject to changes)</b>
                    </p>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=59&sVT=90&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=01/31/25">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Performance;
